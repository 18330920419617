import request from "./axios.js";


/**
 * 门店查询
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getStoreData(data) {
  return request.get("/api/store",{params:data} );
}

/**
 * 门店添加
 *
 * @param {Object} data - 对象数据
 */
export function addStoreData(data) {
  return request.post("/api/store", data);
}

/**
 * 门店查询id
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getStoreDataId(data,id) {
  return request.get("/api/store/"+id,{params:data} );
}

/**
 * 门店删除
 *
 * @param {Object} data - 对象数据
 */
export function deleteStoreDataId(data,id) {
  return request.delete("/api/store/"+id,{params: data});
}


/**
 * 修改
 *
 * @param {Object} data - 对象数据
 */
export function editStoreDataId(data,id) {
  return request.put("/api/store/"+id,data);
}